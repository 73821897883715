import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import imfsLogo from "../images/imfs-logo-text.svg";
import banner from "../video/banner.mp4";
import ledgerImage from "../images/home-page/ledger.jpeg";
import officeImage from "../images/home-page/office-2.jpeg";
import Icon from '@mui/material/Icon';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import StarIcon from '@mui/icons-material/Star';
import smokeImage from "../images/effect/smoke-background.jpeg";

function Home(props) {

	useEffect(() => {

	    $("body").addClass("is-homepage");

	    if($(".ledger-management").length > 0 || $(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);

	});

	return (
		<main className="home">
			<section id="homePageBanner" className="text-center">
				<video 
					autoPlay 
					preload="true" 
					loop 
					muted 
					align="left"
				>
					<source src={ banner } type="video/mp4"/>
				</video>
				<div className="banner-text-container">
			  		<h2>Integrated Contact Solutions</h2> 
			  		<h5>Your all-encompassing business partner:</h5>
			  		<h1>We are a UK leader</h1>
			  		<h3>Debt Recovery, Credit Control & Customer Service</h3>
			  	</div>
			  	{/*<div class="scroll-down-container">
				  <div class="chevron"></div>
				  <div class="chevron"></div>
				  <div class="chevron"></div>
				  <span class="text">Scroll down</span>
				</div>*/}
			</section>
			<div className="homepage-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="homepage-content">
					<section id="imageBanners">
						<div className="image-and-text">
							<div className="image-container">
								<img src={ ledgerImage } alt="ledger-image"/>	
							</div>
							<div className="text">
								ICS connects some of the most respected brands with their customers by providing Debt Recovery & Ledger Management services, CRM, back-office and other specialised services to ensure consistently positive customer interaction. When your customers contact you or vice versa, we are there to support them and make sure they have a unique experience with your brand.
							</div>
						</div>
						<div className="image-and-text reverse">
							<div className="image-container">
								<img src={ officeImage } alt="ledger-image"/>
							</div>
							<div className="text">
								By attracting and retaining the best people, we provide the best working environment to inspire our teams. We know happy employees are more engaged to make a better experience that drives higher customer satisfaction, retention, but overall deliver results.
							</div>
						</div>
					</section>
					<div className="horizontal-rule"></div>
					<h1 id="ourServicesHeader">Our Services</h1>
					<br/>
					<br/>
					<section id="ourServices">			
						<Link className="service" to="/debt-collection">
							<div className="icon-container">		
								<ReceiptLongTwoToneIcon  sx={{ fontSize: 50 }}/>
							</div>
							<h3 className="text">Debt Collection</h3>
						</Link>
						<Link className="service" to="/ledger-management">
							<div className="icon-container">
								<MenuBookTwoToneIcon sx={{ fontSize: 50 }}/>
							</div>
							<h3 className="text">Ledger Management</h3>
						</Link>
						{/*<Link className="service" to="/customer-relationship-management">
							<div className="icon-container">
								<SupportAgentTwoToneIcon sx={{ fontSize: 50 }}/>
							</div>
							<h3 className="text">Customer Relationship</h3>
						</Link>*/}
					</section>
					<br/>
					<br/>
					{/*<div className="horizontal-rule"></div>
					<br/>
					<br/>
					<section id="reviews">
						<div className="review">
							<div className="stars-container">
								<StarIcon/><StarIcon/><StarIcon/><StarIcon/><StarIcon/>
							</div>
							<h3>Best company ever</h3>
							<p>Dave - Some company</p>
						</div>
						<div className="review">
							<div className="stars-container">
								<StarIcon/><StarIcon/><StarIcon/><StarIcon/><StarIcon/>
							</div>
							<h3>Best company ever</h3>
							<p>Dave - Some company</p>
						</div>
						<div className="review">
							<div className="stars-container">
								<StarIcon/><StarIcon/><StarIcon/><StarIcon/><StarIcon/>
							</div>
							<h3>Best company ever</h3>
							<p>Dave - Some company</p>
						</div>
					</section>*/}
				</div>
			</div>
		</main>
	);
}

export default Home;