import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router-dom';

import logo from "../images/ics-eye.svg";
import PhoneIcon from '@mui/icons-material/Phone';

const Navbar = () => {

	useEffect(() => {

		$(window).on("scroll", function() {
        
	        let currentScrollPosition = $(document).scrollTop();
	        
            if(currentScrollPosition >= 250) {
                $("nav.navbar").addClass("darkened");
            } else {
                $("nav.navbar").removeClass("darkened");
          	}

	    });


	    let currentScrollPosition = $(document).scrollTop();
	        
        if(currentScrollPosition >= 250) {
            $("nav.navbar").addClass("darkened");
        } else {
            $("nav.navbar").removeClass("darkened");
      	}

		$("#mobileNavMenu").on("click", function() {
			console.log("Potato");
	        if($(this).hasClass("toggled")) {
	            $(this).removeClass("toggled");
	            $("#mobileCollapseMenu").removeClass("show");
	            $("main, nav, footer").removeClass("showMenu");
	        } else {
	            $(this).addClass("toggled");
	            $("#mobileCollapseMenu").addClass("show");
	            $("main, nav, footer").addClass("showMenu");
	        }
	    });

	    $("#mobileCollapseMenu a").on("click", function() {
	    	$("#mobileNavMenu").removeClass("toggled");
	    	$("#mobileCollapseMenu").removeClass("show");
	    	$("main, nav, footer").removeClass("showMenu");
	    });

	    $(window).on("resize", function() {
	    	if($(window).width() >= 992) {
	    		$("#mobileNavMenu").removeClass("toggled");
	    		$("#mobileCollapseMenu").removeClass("show");
	    		$("main, nav, footer").removeClass("showMenu");
	    	}
	    });

	});

	return ( 
		<nav className="navbar navbar-expand-lg">
		  	<div className="container-fluid">
		    	<div className="navbar-header">
		      		<Link to="/" className="logo navbar-brand">
						<img src={logo} alt="logo"/>
					</Link>

		    	</div>
		    	<div id="companyName">Integrated<br/>Contact<br/>Solutions</div>
		    	<button id="mobileNavMenu" className="navbar-toggler navbar-toggler" type="button" aria-controls="navigation-index" aria-expanded="true" aria-label="Toggle navigation">
					<span className="navbar-toggler-bar bar1"></span>
					<span className="navbar-toggler-bar bar2"></span>
					<span className="navbar-toggler-bar bar3"></span>
				</button>
		    	<div className="navbar-collapse" id="mobileCollapseMenu">
		    		<div id="mobileMenuBrand">
		    			<div id="mobileMenuLogo">
							<img src={logo} alt="logo"/>
						</div>
						<div id="mobileMenuCompanyName">Integrated<br/>Contact<br/>Solutions</div>
					</div>
			    	<ul className="nav navbar-nav navbar-right">
			    		<Dropdown>
					      	<Dropdown.Toggle variant="success" id="servicesDropdownButton">
					        	Services
					      	</Dropdown.Toggle>
					      	<Dropdown.Menu id="servicesDropdownMenu">
					        	<Dropdown.Item 
					        		as={Link}
      								to="/debt-collection"
      								activeClassName="selected"
					        	>Debt Collection</Dropdown.Item>
					        	<Dropdown.Item
					        		as={Link}
      								to="/ledger-management"
      							>Ledger Management</Dropdown.Item>
					        	{/*<Dropdown.Item 
					        		as={Link}
      								to="/customer-relationship-management"
      							>Customer Relationship</Dropdown.Item>*/}
					      	</Dropdown.Menu>
					    </Dropdown>
					    <li className="mobileShow"><NavLink to="/debt-collection" activeClassName="selected">Debt Collection</NavLink></li>
					    <li className="mobileShow"><NavLink to="/ledger-management" activeClassName="selected">Ledger Management</NavLink></li>
			      		<li><NavLink to="/self-service-centre" activeClassName="selected">Self Service Centre</NavLink></li>
			      		<li><NavLink to="/our-values" activeClassName="selected">Our Values</NavLink></li>
			      		<li><NavLink to="/downloads" activeClassName="selected">Downloads</NavLink></li>
			      		<li><NavLink to="/contact-us" activeClassName="selected">Contact Us</NavLink></li>
			      		<li className="login"><a href="https://customer.imfs.co.com/SignIn" target="_blank">Login</a></li>
			      		<li className="telephone"><a href="tel:01133503750"><PhoneIcon/>0113 350 3750</a></li>
			    	</ul>
			    	<div id="developerDisclaimer">Developed by <a href="mailto:cjchilto@gmail.com">CB_Creative</a>.</div>
			    </div>
		  	</div>

		</nav>
		
	);
}

export default Navbar;