import csaImage from "../images/CSA_logo_2014_credit_services_RGB_fromguide_withtext.jpeg";
import pcsDss from "../images/PCI_DSS_Validated_light.png";
import iso27001 from "../images/ISO27001UKAS.png";
import iso9001 from "../images/ISO9001UKAS.png";
import Modal from 'react-bootstrap/Modal';

const Footer = (props: props) => {
	return ( 
		<footer>
			<div className="container">
			  	<div className="row">
			  		<div className="col-md-8">
			  			<div className="footer-text-container">
							<p>Integrated Contact Solutions Ltd is authorised and regulated by the Financial Conduct Authority with firm reference number: 754982.</p>
							<p>&copy; Integrated Contact Solutions Ltd · Registered in England and Wales · Reg No: 09972113</p>
							<p>Registered Office: 4100 Park Approach, Thorpe Park, Leeds, LS15 8GB · VAT No: 232384227 · ICO: ZA168031</p>
						</div>
					</div>
					<div className="col-md-4">
						<div className="footer-accreditation-container">
							<div className="csa-pci-dss">
								<a href="http://www.csa-uk.com" target="_blank">
									<img src={ csaImage } alt="Credit Services Association UK" />
								</a>
								<a href="https://www.securitymetrics.com/site_certificate?id=1573592&amp;tk=f251cf01542fbda791a03b1a8b62ee2a" target="_blank">
									<img className="w3-hide-small" src={ pcsDss } alt="SecurityMetrics Credit Card Safe" />
								</a>
							</div>
							<div className="isos">
								<img onClick={ props.openISO27001 } className="w3-hide-small" src={ iso27001 } alt="ISO 27001 Accredited " />
								<img onClick={ props.openISO9001 } className="w3-hide-small" src={ iso9001 } alt="ISO 9001 Accredited" />
							</div>
						</div>
					</div>
			  </div>
			</div>
		</footer>
		
	);
}

export default Footer;