import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import selfService from "../images/self-service/self-service.jpeg";
import banner from "../images/banners/self-service-banner.jpeg";
import smokeImage from "../images/effect/smoke-background-2.jpeg";

const SelfServiceCentre = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".ledger-management").length > 0 || $(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="customer-relationship">
			<section id="" className="text-center">
				<img src={ banner }/>
			</section>
			<div className="crm-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="crm-content">
					<p>Our cutting-edge PCI compliant self-service portal allows customers to log in to their account remotely, access account summaries, account activity, view documents and make payments, all from the comfort of their home computer or mobile device 24/7/365.</p>				
					<div className="horizontal-rule"></div>
					<section id="imageBanners">
						<div className="image-and-text">
							<div className="image-container crm">
								<img src={ selfService } alt="ledger-image"/>	
							</div>
							<div className="text">
								This service provides real-time account information directly from our leading database systems, whilst the bespoke technology safeguards assure only the account details assigned to the customer's login are accessible.
							</div>
						</div>
					</section>			
					<div className="horizontal-rule"></div>
					<p>Ultimately, a fully customisable service platform, offering as much or as little detail as required, based completely on client requirements. If you currently have an account with IMFS, you may access the <a href="https://customer.imfs.co.com/SignIn" target="_blank">Self-service portal here</a></p>				
					<p>Did you know that webchat capabilities are proven to increase customer interaction by on average 20%?</p>
				</div>
			</div>
		</main>
		
	);
}

export default SelfServiceCentre;