import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import banner from "../images/banners/ledger-management-banner.jpeg";
import smokeImage from "../images/effect/smoke-background-8.jpeg";

const LedgerManagement = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".ledger-management").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="ledger-management">
			<section id="" className="text-center">
				<img src={ banner } type="video/mp4"/>
				<div className="banner-text-container">
			  		{/*<h1>Debt Collection</h1> */}
			  	</div>
			</section>
			<div className="lm-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="lm-content">
					<h3>An all-encompassing service operated under our clients name:</h3>
					<div className="list-container">
						<ul>
							<li><CheckTwoToneIcon/>Early arrears and ledger management solutions designed around individual client requirements.</li>
							<li><CheckTwoToneIcon/>Account Rehabilitation.</li>
							<li><CheckTwoToneIcon/>Tracing Capabilities.</li>
							<li><CheckTwoToneIcon/>Fully functional and bespoke telephone IVR capabilities providing a seamless customer journey.</li>
							<li><CheckTwoToneIcon/>Web portal functionality including online chat.</li>
							<li><CheckTwoToneIcon/>PCI compliant payment gateways.</li>
						</ul>
					</div>	
				</div>
			</div>
		</main>
		
	);
}

export default LedgerManagement;