import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import indebt from "../images/debt-collection/indebt-2.jpeg";
import banner from "../images/banners/debt-collection-3.jpeg";
import smokeImage from "../images/effect/smoke-background-3.jpeg";

const DebtCollection = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="debt-collection">
			<section id="" className="text-center">
				<img src={ banner } type="video/mp4"/>
				<div className="banner-text-container">
			  		{/*<h1>Debt Collection</h1> */}
			  	</div>
			</section>
			<div className="dc-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="dc-content">
					<h3>At ICS, we offer a full range of debt recovery services, including:</h3>
					<div className="list-container">
						<ul>
							<li><CheckTwoToneIcon/>Consumer & Commercial Recoveries (No Collection - No Fee Basis).</li>
							<li><CheckTwoToneIcon/>Account Rehabilitation.</li>
							<li><CheckTwoToneIcon/>Tracing Capabilities.</li>
							<li><CheckTwoToneIcon/>Bespoke telephone IVR capabilities providing a seamless customer journey.</li>
							<li><CheckTwoToneIcon/>Customer web portal functionality including online chat.</li>
							<li><CheckTwoToneIcon/>Realtime client reporting, allowing access 24/7/365</li>
						</ul>
					</div>
					<div className="horizontal-rule"></div>
					<section id="imageBanners">
						<div className="image-and-text reverse">
							<div className="image-container crm">
								<img src={ indebt } alt="ledger-image"/>	
							</div>
							<div className="text">
								We have gained our reputation in the industry because our staff are trained to the highest standards, in the relevant areas of expertise. We offer nationwide coverage and our proven and unique recovery process means that we have industry leading collection rates.
							</div>
						</div>
					</section>							
					<div className="horizontal-rule"></div>
					<p>Using market leading collections systems, we are able to offer a fully customised service, tailored to individual client needs. All can be tailored to match your own branding, should you require.</p>
					<p><strong>As we don't charge you fees if we don't collect, we are highly incentivised to collect on your outstanding accounts.</strong> We simply charge commissionable rates which are agreed prior on monies collected.</p>				
				</div>
			</div>
		</main>
		
	);
}

export default DebtCollection;