import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';

import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import banner from "../images/banners/downloads-banner.jpeg";
import smokeImage from "../images/effect/smoke-background-10.jpeg";

const Downloads = (props: props) => {
 	
	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".ledger-management").length > 0 || $(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="downloads">
			<section id="" className="text-center">
				<img src={ banner } type="video/mp4"/>
				<div className="banner-text-container">
			  		{/*<h1>Debt Collection</h1> */}
			  	</div>
			</section>
			<div className="dl-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="dl-content">
					<h3>Downloads</h3>
					<div>
	        			<div onClick={ props.openISO9001} className="downloadButton">
	        				<OpenInNewIcon/>
	        				<div className="file">
	        					<div className="filename">ISO 9001:2015</div>
	        					<div className="file-description">View our latest certificate of compliance.</div>
	        				</div>
	        			</div>
				        <div onClick={ props.openISO27001 } className="downloadButton">
				        	<OpenInNewIcon/>
	        				<div className="file">
	        					<div className="filename">ISO 27001:2017</div>
				        		<div className="file-description">View our latest certificate of compliance.</div>
	        				</div>
				        </div>
				        <a href="/downloads/ICS Ltd - Privacy Policy.pdf" className="downloadButton">
				        	<DownloadIcon/>
				        	<div className="file">
				        		<div className="filename">Privacy Policy</div>
				        		<div className="file-description">Download and read our full privacy policy.</div>
				        	</div>
				        </a>
				        <a href="/downloads/ICS Ltd - Complaint Handling Process.pdf" className="downloadButton">
				        	<DownloadIcon/>
				        	<div className="file">
				        		<div className="filename">Complaints Procedure</div>
				        		<div className="file-description">Fully explained process document.</div>
				        	</div>
				        </a>

				        <a href="https://www.securitymetrics.com/site_certificate?id=1573592&amp;tk=f251cf01542fbda791a03b1a8b62ee2a" target="_blank" className="downloadButton">
				        	<OpenInNewIcon/>
				        	<div className="file">
				        		<div className="filename">PCI Security Status</div>
				        		<div className="file-description">Our latest results from Security Metrics.</div>
				        	</div>
				        </a>
				        <a href="http://csa-uk.site-ym.com/resource/resmgr/docs/code_of_practice/code_of_practice.pdf" className="downloadButton">
				        	<DownloadIcon/>
				        	<div className="file">
				        		<div className="filename">CSA Code Of Practice</div>
				        		<div className="file-description">The Credit Services Association's Code Of Practice.</div>
				        	</div>
				        </a>
					</div>	
				</div>
			</div>
		</main>
		
	);
}

export default Downloads;