import React, { useState, useEffect } from 'react';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import CustomerRelationshipManagement from './components/CustomerRelationshipManagement';
import DebtCollection from './components/DebtCollection';
import LedgerManagement from './components/LedgerManagement';
import SelfServiceCentre from './components/SelfServiceCentre';
import OurValues from './components/OurValues';
import Downloads from './components/Downloads';
import ContactUs from './components/ContactUs';
import Error404 from './components/Error404';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import iso27001Image from "./images/ISOCert27001.png";
import iso9001Image from "./images/ISOCert9001.png";

import "./ct-kit/assets/css/bootstrap.min.css";
import $ from 'jquery';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {

    function openISO27001() {
        handleShowISO27001();
    }

    const [showISO27001, setShowISO27001] = useState(false);

    const handleCloseISO27001 = () => setShowISO27001(false);
    const handleShowISO27001 = () => setShowISO27001(true);

    function openISO9001() {
        handleShowISO9001();
    }

    const [showISO9001, setShowISO9001] = useState(false);

    const handleCloseISO9001 = () => setShowISO9001(false);
    const handleShowISO9001 = () => setShowISO9001(true);

    return (
        <Router basename="/">   
            <div className="App">
                <Navbar />
                <div className="content">
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route path="/customer-relationship-management">
                            <CustomerRelationshipManagement/>
                        </Route>
                        <Route path="/debt-collection">
                            <DebtCollection/>
                        </Route>
                        <Route path="/ledger-management">
                            <LedgerManagement/>
                        </Route>
                        <Route path="/self-service-centre">
                            <SelfServiceCentre/>
                        </Route>
                        <Route path="/our-values">
                            <OurValues/>
                        </Route>
                        <Route path="/downloads">
                            <Downloads openISO27001={ openISO27001 } openISO9001={ openISO9001 }/>
                        </Route>
                        <Route path="/contact-us">
                            <ContactUs/>
                        </Route>
                        <Route path="*">
                            <Error404/>
                        </Route>
                    </Switch>
                </div>
                <Footer openISO27001={ openISO27001 } openISO9001={ openISO9001 }/>
                <Modal animation={ true } id="iso27001Modal" show={showISO27001} onHide={handleCloseISO27001}>
                    <Modal.Header closeButton>
                        <Modal.Title>ISO 27001</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><img src={ iso27001Image }/></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseISO27001}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal id="iso9001Modal" show={showISO9001} onHide={handleCloseISO9001}>
                    <Modal.Header closeButton>
                        <Modal.Title>ISO 9001</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><img src={ iso9001Image }/></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseISO9001}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Router>
    );
}

export default App;
