import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import ErrorIcon from '@mui/icons-material/Close';
import SuccessIcon from '@mui/icons-material/Done';
// import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import banner from "../images/banners/contact-us-banner.jpeg";
import smokeImage from "../images/effect/smoke-background-4.jpeg";
import loader from "../images/loader2.svg";

const ContactUs = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".ledger-management").length > 0 || $(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	const handleSubmit = (e) => {

		e.preventDefault();

		$("#formSubmitPleaseWait").fadeIn().css("display", "flex");

		let user_name = $("#formFullName").val();
        let user_email = $("#formBasicEmail").val();
        let message = $("#formMessage").val();

        var templateParams = {
            name: user_name,
            email: user_email,
            message: message,
            source: "ics"
        };

        $.ajax({
            url: "https://www.icontactsolutions.co.uk/API/email/",
            method: "POST",
            dataType: "json",
            data: JSON.stringify(templateParams),
            error: function(response) {
                $("#submitContactForm").removeAttr("disabled");
                $("#formSubmitFeedback").addClass("error").removeClass("success").fadeIn(function() {
                	$("#formSubmitPleaseWait").fadeOut();	
                }).css("display", "flex");                
            },
            success: function(response) {
                if(response.error) {
                    $("#submitContactForm").removeAttr("disabled");
                    $("#formSubmitFeedback").addClass("error").removeClass("success").fadeIn(function() {
                		$("#formSubmitPleaseWait").fadeOut();	
                	}).css("display", "flex");
                } else {
                    $("#formSubmitFeedback").addClass("success").removeClass("error").fadeIn(function() {
                    	$("#formSubmitPleaseWait").fadeOut();
                    }).css("display", "flex");    
                }
            }
        });
	}

	const handleDismiss = () => {
        $("#formSubmitFeedback").fadeOut();    
	}

	return ( 
		<main className="contact-us">
			<section id="" className="text-center">
				<img src={ banner }/>
			</section>
			<div className="cu-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="cu-content">
					<h2>Thank you for your interest in the services Integrated Contact Solutions can provide:</h2>
					<br/><br/>
					<h3>Please complete the information below if you wish to:</h3>
					<br/>
					<br/>
					<div className="list-container">
						<ul>
							<li><CheckTwoToneIcon/>Obtain more information about our services and solution options.</li>
							<li><CheckTwoToneIcon/>Request a sales presentation or proposal.</li>
							<li><CheckTwoToneIcon/>Have ICS Ltd engage in the RFI/RFP process for opportunities within your company.</li>
						</ul>
					</div>
					<p>We are already servicing over 40,000 live accounts from numerous industries. Contact us to see how we can help improve your revenue streams whilst cutting your operational overhead charges. Please provide as much detail in your message that you are happy to share with us at this stage. A member of our sales team will reach out to you shortly.</p>	
					<br/>
					<br/>
					<br/>
					<div id="contactUsFormContainer">
						<Form id="contactUsForm" onSubmit={ handleSubmit }>
							<Form.Group className="mb-3" controlId="formFullName">
					        	<Form.Label>Full Name: </Form.Label>
					        	<Form.Control type="text" placeholder="Enter your name" />
					      	</Form.Group>
					      	<Form.Group className="mb-3" controlId="formBasicEmail">
					        	<Form.Label>Email address: </Form.Label>
					        	<Form.Control type="email" placeholder="Enter your email" />
					      	</Form.Group>
					      	<Form.Group className="mb-3" controlId="formMessage">
					        	<Form.Label>Your Message: </Form.Label>
					        	<Form.Control as="textarea" placeholder="Enter your message here" />
					      	</Form.Group>
					      	<Button variant="primary" type="submit">
					        	Submit
					      	</Button>
					    </Form>
				    	<div id="formSubmitPleaseWait">
							<span class="loader"><img src={ loader }/></span>
							<h6>Please wait while your details are received</h6>
						</div>
						<div id="formSubmitFeedback" class="error">
							<span class="errorIcon error"><ErrorIcon/></span>
							<span class="successIcon success"><SuccessIcon/></span>
							<h5 class="error">Unfortunately, there was an error submitting your details. Please try again.</h5>
							<h5 class="success">Your message was successfully received. An IMFS representative will soon be in touch.</h5>
							<button id="dismissContactSubmitError" onClick={ handleDismiss } class="error btn-round btn btn-warning">Try again...</button>
						</div>		
					</div>
				</div>
			</div>
		</main>
		
	);
}

export default ContactUs;