import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import HeartIcon from '@mui/icons-material/Favorite';

import banner from "../images/banners/our-values-banner.jpeg";
import smokeImage from "../images/effect/smoke-background-9.jpeg";

const OurValues = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".ledger-management").length > 0 || $(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="our-values">
			<section id="" className="text-center">
				<img src={ banner }/>
			</section>
			<div className="ov-content-container">
				<img className="smoke-background" src={ smokeImage }/>
				<div className="ov-content">
					<section id="coreValues">
						<div className="value">
							<div className="letter-icon"><HeartIcon/><span className="letter">H</span></div>
							<div className="content">								
								<h3>Honesty</h3>
								<div className="strapline">Know your customer, earn their trust, be it customers/clients/colleagues.</div>
							</div>
						</div>
						<div className="horizontal-rule"></div>
						<div className="value">
							<div className="letter-icon"><HeartIcon/><span className="letter">E</span></div>
							<div className="content">
								<h3>Excellence</h3>
								<div className="strapline">Strive to be/deliver the best in every situation. Celebrate success. Have fun but get the job done.</div>
							</div>
						</div>
						<div className="horizontal-rule"></div>
						<div className="value">
							<div className="letter-icon"><HeartIcon/><span className="letter">A</span></div>
							<div className="content">
								<h3>Accountability</h3>
								<div className="strapline">Dedication and commitment leads to infinite potential. You can make it happen. If it's to be, it's up to me.</div>
							</div>
						</div>
						<div className="horizontal-rule"></div>
						<div className="value">
							<div className="letter-icon"><HeartIcon/><span className="letter">R</span></div>
							<div className="content">
								<h3>Respect</h3>
								<div className="strapline">For yourself and everyone around you. Value relationships with your customer/clients/colleagues.</div>
							</div>
						</div>
						<div className="horizontal-rule"></div>
						<div className="value">
							<div className="letter-icon"><HeartIcon/><span className="letter">T</span></div>
							<div className="content">
								<h3>Teamwork</h3>
								<div className="strapline">Positive team who make a difference by sharing/showing kindness and compassion. We want to help.</div>
							</div> 
						</div>
					</section>
					<h5>Customer Service Specialists with H.E.A.R.T</h5>
					<p>ICS will continue to service our clients and their customers throughout the entirety of the customer journey. We will use our specialist expertise to ensure we achieve the best possible outcomes for your customers and our clients.</p>
					<br/><br/><br/>
				</div>
			</div>
		</main>
		
	);
}

export default OurValues;