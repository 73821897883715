import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import banner from "../images/banners/404-banner.jpeg";

const Error404 = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		if($(".ledger-management").length > 0 || $(".debt-collection").length > 0) {
	    	$("#servicesDropdownButton").addClass("selected");
	    } else {
	    	$("#servicesDropdownButton").removeClass("selected");
	    }
	    $("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="error404">
			<section id="" className="text-center">
				<img src={ banner }/>
			</section>
		</main>
		
	);
}

export default Error404;