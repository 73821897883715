import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Dropdown from 'react-bootstrap/Dropdown';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import crmAgent from "../images/customer-relationship-management/crm-agent.jpeg";
import banner from "../images/banners/customer-relationship-banner.jpeg";

const CustomerRelationshipManagement = () => {

	useEffect(() => {
		// Add navbar check here.
		$("body").removeClass("is-homepage");
		$("html, body").animate({scrollTop: 0}, 0);
	});

	return ( 
		<main className="customer-relationship">
			<section id="" className="text-center">
				<img src={ banner } type="video/mp4"/>
				<div className="banner-text-container">
			  		<h1>Flexible Contact Center Solutions</h1> 
			  	</div>
			</section>
			<div className="crm-content">
				<p>You can count on ICS to deliver outstanding customer experiences at every single opportunity. It costs much more to try to acquire a new customer than to retain an existing one. That's why building long-lasting customer relationships is a priority to us.</p>
				<div className="horizontal-rule"></div>
				<p>ICS Express delivers an adaptable alternative for the typical CRM solution: we can quickly increase capacity, quality and performance without any additional capital expense or management overhead. It's the quickest, easiest and most cost-effective way to gain a competitive advantage through an improved customer experience platform. As we are able to offer end to end life cycle of the customer journey. From the initial call, to fully outsourced postal services, SMS campaigns, payment gateways and online customer service interaction.</p>
				<div className="horizontal-rule"></div>
				<section id="imageBanners">
					<div className="image-and-text">
						<div className="image-container crm">
							<img src={ crmAgent } alt="ledger-image"/>	
						</div>
						<div className="text">
							<ul>
								<li><CheckTwoToneIcon/>Improved Customer Journey - Optimised customer service with top quality agents.</li>
								<li><CheckTwoToneIcon/>Financial Flexibility - Freedom from hidden setup costs and software investment.</li>
								<li><CheckTwoToneIcon/>Guaranteed Reliability & Flexibility - Superior Systems and availability.</li>
								<li><CheckTwoToneIcon/>Above All - Quantifiable Results.</li>
							</ul>
						</div>
					</div>
				</section>			
				<div className="horizontal-rule"></div>
				<p>We provide the necessary resources and capacbilities without incurring upfront investment costs, months of development and training time or risky technology implementation.</p>				
			</div>
		</main>
		
	);
}

export default CustomerRelationshipManagement;